import React from 'react';

export class InvalidatedIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 6.0854165 6.0854168" {...this.props}>
        <g>
          <path
            d="M 1.6494348,3.0427358 0.14344383,1.5367438 C 0.04778383,1.4411638 -1.6666667e-7,1.3250668 -1.6666667e-7,1.1884228 -1.6666667e-7,1.0518148 0.04789983,0.93573183 0.14344383,0.84010283 l 0.696623,-0.696695 C 0.93567683,0.04781783 1.0517608,-1.6666667e-7 1.1883328,-1.6666667e-7 c 0.136662,0 0.25271,0.0478199966667 0.348375,0.14340799666667 l 1.505973,1.50599197 1.50601,-1.50599197 c 0.09556,-0.09559 0.211658,-0.14340799666667 0.348319,-0.14340799666667 0.136555,0 0.252674,0.0478199966667 0.348321,0.14340799666667 l 0.696659,0.696695 c 0.09558,0.09556 0.143427,0.21171197 0.143427,0.34831997 0,0.136644 -0.0478,0.252692 -0.143427,0.348321 l -1.505973,1.505992 1.505973,1.50599 c 0.09558,0.09565 0.143427,0.211694 0.143427,0.348374 0,0.136537 -0.0478,0.252656 -0.143427,0.348249 l -0.696676,0.696641 c -0.09563,0.09566 -0.211767,0.143427 -0.348321,0.143427 -0.136644,0 -0.252691,-0.04789 -0.34832,-0.143427 l -1.50601,-1.505991 -1.506099,1.505991 c -0.09559,0.09566 -0.211586,0.143427 -0.348374,0.143427 -0.136554,0 -0.25265497,-0.04789 -0.34826597,-0.143427 l -0.696498,-0.696641 C 0.04775583,5.1498288 -1.6666668e-7,5.0336368 -1.6666668e-7,4.8970998 c 0,-0.13668 0.0477599966667,-0.252726 0.14342599666668,-0.348374 z"
            fill="currentColor"
            style={{ mixBlendMode: 'multiply', strokeWidth: 0.267323 }}
          />
        </g>
      </svg>
    );
  }
}
