import React from 'react';

export class RefugeeResponseIcon extends React.PureComponent {
  render() {
    return (
      <svg width="53" height="70" viewBox="0 0 53 70" {...this.props}>
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" d="M3 2h47v65H3z" />
          <g fillRule="nonzero">
            <path
              d="M26.474 13.826c-7.54 0-13.654 6.113-13.654 13.654 0 7.542 6.113 13.655 13.654 13.655 7.541 0 13.655-6.113 13.655-13.655 0-7.54-6.114-13.654-13.655-13.654zM22.11 17.13c-.69.966-1.276 2.157-1.728 3.524h-2.828a11.323 11.323 0 0 1 4.556-3.524zm-5.669 5.285h3.455a22.703 22.703 0 0 0-.525 4.21h-4.114c.117-1.505.53-2.929 1.184-4.21zm-.025 10.131a11.18 11.18 0 0 1-1.159-4.16h4.116a22.78 22.78 0 0 0 .523 4.16h-3.48zm1.1 1.763h2.866c.462 1.397 1.065 2.614 1.774 3.59a11.31 11.31 0 0 1-4.64-3.59zm8.078 4.266c-1.34-.489-2.528-2.066-3.344-4.266h3.344v4.266zm0-6.029h-3.881a20.858 20.858 0 0 1-.577-4.16h4.457v4.16zm0-5.922h-4.459c.056-1.496.26-2.92.579-4.21h3.88v4.21zm0-5.97h-3.344c.816-2.2 2.004-3.778 3.344-4.266v4.266zm9.803 0h-2.827c-.453-1.365-1.039-2.558-1.728-3.523a11.304 11.304 0 0 1 4.555 3.523zm-8.04-4.267c1.34.489 2.529 2.065 3.344 4.267h-3.344v-4.267zm-.001 6.028h3.883c.318 1.289.52 2.713.578 4.21h-4.46v-4.21h-.001zm4.46 5.971a20.874 20.874 0 0 1-.577 4.16h-3.882v-4.16h4.459zm-4.46 10.189v-4.266H30.7c-.815 2.2-2.004 3.778-3.344 4.266zm3.439-.676c.71-.976 1.313-2.193 1.775-3.59h2.866a11.316 11.316 0 0 1-4.641 3.59zm5.74-5.353h-3.48c.29-1.287.47-2.687.523-4.16h4.116a11.153 11.153 0 0 1-1.158 4.16zm1.16-5.92h-4.115a22.784 22.784 0 0 0-.525-4.21h3.456a11.16 11.16 0 0 1 1.183 4.21z"
              fill="currentColor"
            />
            <path
              d="M47.47.612H5.48A4.85 4.85 0 0 0 .635 5.458v59.316A4.85 4.85 0 0 0 5.48 69.62h41.99a4.852 4.852 0 0 0 4.846-4.846V5.457A4.852 4.852 0 0 0 47.471.612zm2.203 64.162a2.205 2.205 0 0 1-2.202 2.202H5.48a2.205 2.205 0 0 1-2.204-2.202V5.457c0-1.214.988-2.202 2.204-2.202h41.99c1.215 0 2.203.987 2.203 2.202v59.317z"
              fill="#2C3038"
            />
            <rect
              fill="currentColor"
              x="12.821"
              y="53.81"
              width="27.309"
              height="2.643"
              rx="1.321"
            />
          </g>
        </g>
      </svg>
    );
  }
}
