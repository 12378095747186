import React from 'react';

export class HumanProcessingIcon extends React.PureComponent {
  render() {
    return (
      <svg width="100" height="100" viewBox="0 0 100 100" {...this.props}>
        <g fillRule="nonzero" fill="none">
          <path
            d="M45.841 0C27 0 11.683 15.227 11.683 34.06v5.064a20.562 20.562 0 0 1-6.048 14.56l-4.703 4.69c-1.991 1.985-.581 5.382 2.236 5.382h6.764c.967 0 1.751.782 1.751 1.746v5.786c0 8.647 7.095 15.72 15.766 15.72 3.38 0 6.13 2.733 6.13 6.114v5.022A1.86 1.86 0 0 0 35.441 100h29.095a1.886 1.886 0 0 0 1.888-1.883v-12.88c0-5.05 1.688-10.02 5.038-13.807C76.775 65.427 80 57.544 80 48.908V34.061C80 15.25 64.706 0 45.841 0z"
            fillOpacity=".25"
            fill="#929DB3"
          />
          <path
            d="M51.689 49.155a19.156 19.156 0 0 1-2.79-9.97c0-10.777 8.893-19.512 19.865-19.512.078 0 .157.005.236.006V8.003L68.748 8C51.214 8 37 21.962 37 39.185c0 5.772 1.6 11.177 4.383 15.815l10.306-5.845zM78.393 57A18.516 18.516 0 0 1 69 59.566V71a29.582 29.582 0 0 0 15-4.097L78.393 57z"
            fill="#929DB3"
            opacity=".25"
          />
          <path
            d="M69 59.566A20.77 20.77 0 0 1 58.98 57L53 66.903C57.753 69.526 63.261 71 69 71V59.566zM78 56.46L84.084 67A32.572 32.572 0 0 0 96 55.084L85.46 49A20.38 20.38 0 0 1 78 56.46z"
            fill="currentColor"
            style={{
              mixBlendMode: 'multiply',
            }}
          />
          <path
            d="M88.566 40c0 3.19-.831 6.445-2.566 9.393L95.903 55A29.57 29.57 0 0 0 100 40H88.566z"
            fill="#929DB3"
            opacity=".25"
          />
          <path
            d="M88.566 40H100a33.127 33.127 0 0 0-4.097-16L86 29.98A20.758 20.758 0 0 1 88.566 40z"
            fill="currentColor"
            style={{
              mixBlendMode: 'multiply',
            }}
          />
          <path
            d="M84.084 12L78 22.54A20.372 20.372 0 0 1 85.46 30L96 23.916A32.52 32.52 0 0 0 84.084 12z"
            fill="#929DB3"
            opacity=".25"
          />
          <path
            d="M69 19.434c3.393 0 6.62.935 9.393 2.566L84 12.097A29.557 29.557 0 0 0 69 8v11.434z"
            fill="currentColor"
            style={{
              mixBlendMode: 'multiply',
            }}
          />
          <path
            d="M52.915 67L59 56.46A20.398 20.398 0 0 1 51.539 49L41 55.084A32.57 32.57 0 0 0 52.915 67z"
            fill="#929DB3"
            opacity=".25"
          />
        </g>
      </svg>
    );
  }
}
