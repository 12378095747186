import React from 'react';

export class HealthIcon extends React.PureComponent {
  render() {
    return (
      <svg width="77" height="60" viewBox="0 0 77 60" {...this.props}>
        <g fill="none" fillRule="evenodd">
          <g fillRule="nonzero">
            <path
              d="M21.153 3.078C11.612 3.078 3.85 10.83 3.85 20.36c0 5.472 2.565 10.516 7.037 13.84l27.591 21.366L66.05 34.219c4.498-3.34 7.063-8.383 7.063-13.858 0-9.53-7.76-17.284-17.298-17.284A17.302 17.302 0 0 0 39.95 13.48l-1.47 3.378-1.472-3.378A17.296 17.296 0 0 0 21.153 3.078z"
              fill="#FFF"
            />
            <path
              d="M38.498 59.767L8.966 36.898C3.696 32.982.66 27.001.66 20.506.66 9.208 9.862.018 21.172.018a20.496 20.496 0 0 1 17.326 9.55A20.508 20.508 0 0 1 55.834.019c11.308 0 20.506 9.191 20.506 20.488 0 6.5-3.037 12.481-8.332 16.411l-29.51 22.85zM21.172 3.222c-9.542 0-17.304 7.753-17.304 17.284 0 5.47 2.566 10.515 7.037 13.839L38.497 55.71l27.571-21.348c4.498-3.339 7.064-8.383 7.064-13.858 0-9.53-7.76-17.284-17.298-17.284a17.302 17.302 0 0 0-15.865 10.403l-1.471 3.378-1.471-3.378A17.296 17.296 0 0 0 21.172 3.222z"
              fill="#2C3038"
            />
            <path
              fill="currentColor"
              d="M31.455 29.7l-.61 1.743H20.591v-2.85h8.228l2.314-6.604 6.014 13.457 6.33-19.468 4.935 13.326h7.997v2.851h-9.985l-2.77-7.486-6.085 18.712z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
