import React from 'react';

export class ExitIcon extends React.PureComponent {
  render() {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" {...this.props}>
        <g fill="currentColor" fillRule="nonzero">
          <path d="M15.967 7.065a.673.673 0 0 0-.144-.217L13.82 4.852a.67.67 0 0 0-.944 0 .663.663 0 0 0 0 .941l.863.86h-3.728a.666.666 0 1 0 0 1.331h3.728l-.863.86a.663.663 0 0 0 .472 1.136c.17 0 .342-.064.472-.195l2.002-1.995a.664.664 0 0 0 .145-.725z" />
          <path d="M11.347 9.315a.666.666 0 0 0-.668.666v3.326H8.01V2.661a.666.666 0 0 0-.475-.637l-2.319-.693h5.464v3.327c0 .367.299.665.668.665.369 0 .667-.298.667-.665V.665A.666.666 0 0 0 11.347 0H.667C.643 0 .622.01.6.013A.67.67 0 0 0 .316.11C.3.121.283.121.269.131.264.137.262.144.256.148a.66.66 0 0 0-.177.21C.069.375.067.395.06.413.04.463.015.513.007.57.004.589.01.607.01.627.01.64 0 .652 0 .665v13.308c0 .317.225.59.537.652l6.674 1.33a.668.668 0 0 0 .798-.652v-.665h3.338c.369 0 .667-.298.667-.665V9.98a.666.666 0 0 0-.667-.666z" />
        </g>
      </svg>
    );
  }
}
