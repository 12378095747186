import React from 'react';

export class InstagramIcon extends React.PureComponent {
  render() {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" {...this.props} aria-label="Instagram">
        <g fill="none" fillRule="evenodd">
          <path d="M-13-13h40v40h-40z" />
          <path
            d="M7.016.028c-1.897 0-2.135.008-2.88.042-.743.034-1.25.152-1.695.324-.46.179-.849.418-1.237.806a3.423 3.423 0 0 0-.806 1.237c-.172.444-.29.952-.324 1.695-.034.745-.042.983-.042 2.88 0 1.896.008 2.134.042 2.88.034.742.152 1.25.324 1.694.179.46.418.85.806 1.237.388.389.778.627 1.237.806.444.173.952.29 1.695.325.745.034.983.042 2.88.042 1.896 0 2.134-.008 2.88-.042.742-.034 1.25-.152 1.694-.325.46-.179.85-.417 1.237-.806.389-.388.627-.777.806-1.237.173-.444.29-.952.325-1.695.034-.745.042-.983.042-2.88 0-1.896-.008-2.134-.042-2.879-.034-.743-.152-1.25-.325-1.695a3.423 3.423 0 0 0-.806-1.237A3.423 3.423 0 0 0 11.59.394c-.444-.172-.952-.29-1.695-.324C9.15.036 8.912.028 7.015.028m0 1.258c1.865 0 2.086.007 2.823.04.68.032 1.05.146 1.297.241.326.127.558.278.803.523.244.244.395.477.522.803.096.246.21.616.24 1.297.034.736.041.957.041 2.822 0 1.864-.007 2.085-.04 2.822-.032.68-.145 1.05-.241 1.297a2.164 2.164 0 0 1-.522.803 2.164 2.164 0 0 1-.803.522c-.247.096-.616.21-1.297.24-.737.034-.957.041-2.822.041-1.865 0-2.086-.007-2.822-.04-.681-.032-1.051-.145-1.297-.241a2.164 2.164 0 0 1-.803-.522 2.164 2.164 0 0 1-.523-.803c-.095-.247-.21-.616-.24-1.297-.034-.737-.04-.958-.04-2.822 0-1.865.006-2.086.04-2.822.03-.681.145-1.051.24-1.297.127-.326.278-.559.523-.803.244-.245.477-.396.803-.523.246-.095.616-.21 1.296-.24.737-.034.958-.04 2.823-.04"
            fill="currentColor"
            fillRule="nonzero"
          />
          <path
            d="M7.016 9.34a2.328 2.328 0 1 1 0-4.656 2.328 2.328 0 0 1 0 4.656m0-5.915a3.586 3.586 0 1 0 0 7.173 3.586 3.586 0 0 0 0-7.173m4.566-.141a.838.838 0 1 1-1.676 0 .838.838 0 0 1 1.676 0"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
}
