import React from 'react';

export class TwitterIcon extends React.PureComponent {
  render() {
    return (
      <svg width="15" height="14" viewBox="0 0 15 14" {...this.props} aria-label="Twitter">
        <g fill="none" fillRule="evenodd">
          <path d="M-13-13h40v40h-40z" />
          <path
            d="M12.394 14h-10.5a1.75 1.75 0 0 1-1.75-1.75V1.75C.144.784.928 0 1.894 0h10.5c.966 0 1.75.784 1.75 1.75v10.5a1.75 1.75 0 0 1-1.75 1.75zM5.52 10.556c3.3 0 5.107-2.737 5.107-5.106 0-.078 0-.154-.004-.232.35-.252.655-.57.896-.93a3.637 3.637 0 0 1-1.032.283c.37-.22.654-.574.79-.994a3.57 3.57 0 0 1-1.14.434 1.797 1.797 0 0 0-3.059 1.638 5.096 5.096 0 0 1-3.7-1.876 1.8 1.8 0 0 0 .556 2.398 1.815 1.815 0 0 1-.811-.224v.024c0 .868.619 1.596 1.438 1.76a1.75 1.75 0 0 1-.808.032 1.795 1.795 0 0 0 1.676 1.246 3.6 3.6 0 0 1-2.656.745 5.105 5.105 0 0 0 2.747.802z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
}
