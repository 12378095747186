import React from 'react';

export class TaskIcon extends React.PureComponent {
  render() {
    return (
      <svg width="25" height="23" viewBox="0 0 25 23" {...this.props}>
        <g fill="none" fillRule="evenodd">
          <path d="M-8-9h39.89v40H-8z" opacity=".25" />
          <g fill="currentColor" fillRule="nonzero">
            <path d="M24.457 15.579l-11.225-5.007V8.136l1.029-1.03h3.524a.784.784 0 0 0 .783-.784V.784A.784.784 0 0 0 17.785 0H7.215a.784.784 0 0 0-.783.784v5.538c0 .432.35.783.783.783h3.51l1.043 1.033v2.434L.543 15.579a.918.918 0 0 0 0 1.675l11.584 5.167a.915.915 0 0 0 .746 0l11.584-5.167a.918.918 0 0 0 0-1.675zm-7.729 3.516L14.28 17.97l2.18-1 2.516 1.122-2.248 1.003zm-4.205-1.932l-2.154-.989 2.154-.96 2.154.96-2.154.99zm-6.476.94l2.539-1.133 2.18 1-2.472 1.135-2.247-1.003zm2.696-4.576l1.98.883-2.128.95-1.95-.896 2.098-.937zm3.757-1.676l1.98.883-1.957.873-1.98-.883 1.957-.873zm1.823 2.56l1.957-.874 2.099.937-1.928.885-2.128-.949zm-3.318-8.773H7.897v-4.17h9.206v4.17h-3.125a.778.778 0 0 0-.553.23l-.928.93-.942-.934a.777.777 0 0 0-.55-.226zM4.86 15.259l1.95.896-2.565 1.144-1.98-.883 2.595-1.157zm7.639 5.722l-2.422-1.08 2.445-1.124 2.422 1.113-2.445 1.091zm8.277-3.692l-2.543-1.134 1.928-.886 2.571 1.147-1.956.873z" />
            <path d="M16.25 3.125c0-.345-.354-.625-.79-.625H9.54c-.436 0-.79.28-.79.625s.354.625.79.625h5.92c.436 0 .79-.28.79-.625z" />
          </g>
        </g>
      </svg>
    );
  }
}
